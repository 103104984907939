.pageWrapper {
  min-height: 100%;
}

.contentWrapper {
  position: relative;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: -30px;
  margin-right: -30px;

  //padding-top: 0;
  //height: 1200px;
  //
  //&:before {
  //  content: '';
  //  display: block;
  //  position: sticky;
  //  top: 0px;
  //  width: 100%;
  //  height: 80px;
  //  background-color: #ffffff;
  //  z-index: 4;
  //  border-bottom: 2px solid #e9e9e6;
  //}
}

.tabs {
  min-width: 100%;
  display: flex;

  :global(.bx--tabs__nav-item) {
    flex-grow: 1;
  }
}
