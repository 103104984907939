.tabs {
  height: 32px;

  :global(.cds--tabs__nav-item) {
    height: 32px;
    min-width: 140px;

    @media (max-width: 66rem) {
      min-width: inherit;
    }
  }

  :global(.cds--tab--overflow-nav-button) {
    max-height: 32px;
    max-width: 32px;
  }
}
