.wrapper {
  position: relative;
}

.loader {
  display: flex;
  padding: 15px;
  justify-content: center;
  text-align: center;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
}
