$columns: 12;
$gutter-width: 10px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@mixin generate-columns($columns, $gutter-width) {
  @for $i from 1 through $columns {
    .col-#{$i} {
      flex: 0 0 calc((100% / $columns) * $i - 2 * $gutter-width);
      max-width: calc((100% / $columns) * $i - 2 * $gutter-width);
      padding: 0 $gutter-width;
    }
  }

  @each $breakpoint, $min-width in $grid-breakpoints {
    @media (max-width: $min-width - 0.02px) {
      @for $i from 1 through $columns {
        .col-#{$breakpoint}-#{$i} {
          flex: 0 0 calc((100% / $columns) * $i - 2 * $gutter-width);
          max-width: calc((100% / $columns) * $i - 2 * $gutter-width);
          padding: 0 $gutter-width;
        }
      }
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter-width;
  margin-left: -$gutter-width;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.gutters-15 {
  margin-right: -15px;
  margin-left: -15px;

  > [class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@include generate-columns($columns, $gutter-width);
