.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-height: 100%;
  padding: 48px 30px 30px;

  @media (max-width: 66rem) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.content {
  margin-top: 30px;
  min-height: 100%;
}

:global {
  body {
    overflow-x: hidden;
  }
}
