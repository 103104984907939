.taskStatusesFilter {
  min-width: 250px;
  margin-right: auto;

  :global(.cds--label) {
    margin-bottom: 6px;
  }
}

.filterToolbar {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 1;
}

.extraFilters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
}

.toolbarSpacer {
  justify-content: flex-start;
  flex-direction: row;
  flex: 1 0;
  display: flex;
}
