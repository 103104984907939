.modal-open {
  body {
    overflow: hidden;
  }

  &.isWindows body {
    padding-right: 17px;
  }
}

.modal-root {
  position: relative;
  z-index: 9040;
}
