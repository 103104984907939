$photoSize: 70px;

.personCard {
  display: flex;
  gap: 1rem;

  margin-left: calc(#{$photoSize} + 1rem);

  @media (max-width: 42rem) {
    flex-wrap: wrap;

    .userData,
    .userDetails {
      width: 100%;
    }
  }

  .userData {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 30px;
    margin-left: calc(-#{$photoSize} - 1rem);

    font-size: 14px;

    @media (max-width: 42rem) {
      align-items: flex-start;
      margin-right: 0;
    }

    .photo {
      align-self: flex-start;

      img {
        width: $photoSize;
        min-width: $photoSize;
        height: $photoSize;
      }
    }

    .name {
      display: flex;
      margin-bottom: 5px;

      :global(.cds--tag) {
        font-size: 14px;
        line-height: 20px;
        margin-inline: 0;
        margin: 0 0 0 5px;
      }
    }
  }

  .property {
    > label {
      color: #8d8d8d;
      margin-right: 5px;
    }
  }

  .button {
    padding-inline-end: calc(var(--cds-layout-density-padding-inline-local) - 0.0625rem);
  }
}

.breadcrumb {
  display: inline-flex;

  > div {
    padding: 0 3px;
  }

  &Item {
    position: relative;
    cursor: pointer;
    color: #8d8d8d;
    text-decoration: underline;

    &:hover {
      color: var(--cds-link-primary, #0062fe);
    }

    &Last {
      color: var(--cds-link-primary, #0062fe);
    }
  }

  .breadcrumbDelimiter {
    padding-left: 5px;
    padding-right: 5px;
  }
}
