.filterableSelect {
  inline-size: auto;
  min-width: 250px;
  max-width: 250px;

  @media (max-width: 42rem) {
    min-width: 100%;
    max-width: 100%;
  }

  input {
    &::placeholder {
      color: #161616 !important;
    }
  }
}

.none {
  display: none;
}

.nonExpandable {
  &:hover {
    background-color: transparent !important;
  }

  button {
    display: none;
  }
}

.heatTable {
  > div {
    width: 100%;
  }
}
