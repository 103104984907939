.childCards {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  > div {
    width: 100%;
    //max-width: calc(50% - 5px);
  }
}
