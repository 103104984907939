.main {
  justify-content: flex-start;
  flex-direction: row;
  flex: 1 0;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tag {
  align-self: center;

  span {
    font-weight: 500;
    padding: 0 2px;
  }
}

.error {
  color: #da1e28;
}
