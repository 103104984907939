.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;

  &Item {
    flex: 1 0 auto;
  }

  .lastButton {
    flex: 0;
  }
}

.table {
  > div {
    width: 100%;
    //max-height: 33rem;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.col {
  max-width: 40rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.option {
  div {
    white-space: normal;
  }
}
