.watchersTable {
  :global(td:nth-child(2)) {
    width: 32px;
  }
  :global(svg) {
    cursor: pointer;
  }
}
.watchersToolbar:global(.cds--row) {
  justify-content: space-between;
}
