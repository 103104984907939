.list {
  border: solid 1px black;
  height: 13rem;
  min-width: 28rem;
  overflow-y: scroll;
  margin-bottom: 1rem;
}

.row {
  padding: 0.5rem;
  border-bottom: solid 1px #e0e0e0;
  display: grid;
  grid-template-columns: 26rem max-content;
  gap: 0.5rem;
}

.info {
  border: none;
  height: 100%;
  align-content: center;
  padding: 1rem;
}
