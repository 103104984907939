.accordion {
  &.default {
    .item {
      background: #ffffff;
      border: 1px solid #e9e9e6;
      border-radius: 0;
      .titleBold {
        font-weight: bold;
      }
      .title {
        cursor: pointer;
        padding: 10px 44px 10px 20px;
        background-color: rgb(224, 224, 224);
        position: relative;

        &:before {
          position: absolute;
          display: block;
          right: 10px;
          top: 5px;
          content: '';
          background: url('~@assets/images/ui-arrow-down.svg') center no-repeat;
          background-size: cover;
          width: 28px;
          height: 28px;
        }
      }

      .content {
        border-top: 1px solid #e9e9e6;
        padding: 15px 20px;
      }
    }

    .itemActive {
      .title {
        &:before {
          transform: rotate(180deg);
        }
      }
    }

    .item + .item {
      margin-top: 10px;
    }
  }
}
