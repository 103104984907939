.comboBox {
  .cds--select {
    display: inline-block;
  }

  li[aria-selected='true'] {
    border: 1px solid #8a3ffc;
  }

  ul {
    z-index: 100;
  }
}
