$colorLevels: (
  1: #ff6f61,
  2: #8a3ffc,
  3: #ffcd1c,
  4: #ff6f61,
  5: #88b04b,
  6: #955251,
  7: #f7cac9,
  8: #92a8d1,
);

$defaultColor: #e9e9e6;
$borderColor: $defaultColor;

@function get-color($level) {
  $color: map-get($colorLevels, $level);
  @if $color {
    @return $color;
  } @else {
    @return $defaultColor;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card {
  position: relative;
  padding: 3px 5px 3px 10px;
  background-color: #ffffff;
  border: 1px solid $borderColor;
  border-left-width: 2px;
  z-index: 3;

  color: var(--cds-text-primary, #161616);
  text-decoration: none;

  &.top {
    border-width: 1px;
  }

  &.isDepartment {
    //position: sticky;
    //top: 0;
    //z-index: 5;
    //
    //&:hover {
    //  z-index: 6;
    //}
    //
    //min-height: 80px;

    min-width: 200px;

    @for $i from 1 through length(map-keys($colorLevels)) {
      .level-#{$i} & {
        border-left-color: get-color($i);
      }
    }
  }

  &.isPerson {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.3;
    border-width: 1px;
    border-radius: 7px;
    cursor: pointer;
  }

  &.isManager {
    background-color: #faf6fe;
    border-color: #faf6fe;

    .subTitle {
      color: #161616;
    }
  }

  &.notBright {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .title {
    font-weight: bold;

    > div {
      display: inline;
    }

    &Meta {
      font-weight: normal;
    }
  }

  .subTitle {
    color: #8d8d8d;
    font-size: 13px;
  }

  .linkTag {
    cursor: pointer;

    :global(.cds--tag) {
      cursor: pointer;
      //margin: 5px 0
    }
  }
}

.isManager {
  background-color: #faf6fe;
}

//.wrapper {
//  &:has(.card:hover) > .card,
//  .card:hover {
//    border-color: #da1e28;
//  }
//}

$size: 20px;

.button {
  position: relative;
  width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $borderColor;
  margin-top: calc($size / 2) - 1px;
  background-color: #ffffff;
  cursor: pointer;
  z-index: 3;

  //@for $i from 1 through length(map-keys($colorLevels)) {
  //  .level-#{$i} & {
  //    border-color: get-color($i)
  //  }
  //}

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    width: 1px;
    height: calc($size / 2);
    background-color: $borderColor;

    //@for $i from 1 through length(map-keys($colorLevels)) {
    //  .level-#{$i} & {
    //    background-color: get-color($i)
    //  }
    //}
  }
}

.departments {
  display: grid;
  gap: calc($size / 2);
  position: relative;
  margin-left: $size * 1.5;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: calc($size / -2);
    left: -$size;
    width: 1px;
    height: calc(100% - $size / 2);
    background-color: $borderColor;

    //@for $i from 1 through length(map-keys($colorLevels)) {
    //  .level-#{$i} & {
    //    background-color: get-color($i)
    //  }
    //}
  }

  &Item {
    position: relative;

    &:last-child {
      margin-bottom: calc($size / 2);
    }

    $top: $size - 1px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -$size;
      top: $top;
      width: $size;
      height: 1px;
      background-color: $borderColor;

      //@for $i from 1 through length(map-keys($colorLevels)) {
      //  .level-#{$i} & {
      //    background-color: get-color($i+1)
      //  }
      //}
    }

    &:last-child:after {
      content: '';
      display: block;
      position: absolute;
      left: -$size;
      top: calc(#{$top} + 1px);
      width: 1px;
      height: 100%;
      background-color: #ffffff;
      z-index: 1;
    }
  }
}

.persons {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: calc($size / 4);
  padding: calc($size / 4);
  border: 1px solid $borderColor;
  border-radius: 10px;
  margin-top: -$size;
  margin-left: calc($size + $size / 2);

  //@for $i from 1 through length(map-keys($colorLevels)) {
  //  .level-#{$i} & {
  //    border-color: get-color($i)
  //  }
  //}

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: -$size;
    height: 1px;
    background-color: $borderColor;

    //@for $i from 1 through length(map-keys($colorLevels)) {
    //  .level-#{$i} & {
    //    background-color: get-color($i)
    //  }
    //}

    width: $size;
    top: calc($size / 2);
  }

  &:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: -$size - 1px;
    width: 1px;
    height: 100%;
    background-color: $borderColor;

    //@for $i from 1 through length(map-keys($colorLevels)) {
    //  .level-#{$i} & {
    //    background-color: get-color($i)
    //  }
    //}
  }

  &Single {
    padding: 0;
    border: none;
  }

  &Item {
    position: relative;
  }
}

.persons + .departments {
  margin-top: calc($size / 2);
}
