.Title {
  h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
  }

  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  h5 {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}
