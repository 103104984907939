.taskInfo {
  padding-bottom: 15px;
  padding-left: 16px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e9e9e6;
  background: #ffffff;

  @media (min-width: 66rem) {
    position: sticky !important;
    top: 0 !important;
    z-index: 10;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}
:global(.cds--btn-set) .changeTaskStatusButton:global(.cds--btn) {
  inline-size: max-content;
  max-inline-size: unset;
}

.buttonsWrap {
  gap: 1rem;
}

.calendar {
  input {
    inline-size: 28.7rem !important;
  }
}
