.tableWrapper {
  padding: 1rem;
}

.row {
  & + & {
    border-top: 1px solid #e9e9e6;
  }

  &Header {
    .column {
      font-weight: bold;
    }
  }
}

.column {
  padding: 10px;
}
