.wrapper {
  min-height: 100%;
  overflow-y: auto;
}

.item {
  & + & {
    margin-top: 30px;
  }
}

.header {
  display: flex;
  margin-bottom: 5px;
  padding: 3px 5px;
  background-color: #f4f4f4;

  .author {
    font-weight: 700;
  }

  .date {
    margin-left: auto;
    padding-left: 10px;
    font-size: small;
    color: #8d8d8d;
  }
}

.group {
  & + & {
    margin-top: 10px;
  }

  .indicator {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #f4f4f4;
    align-self: center;
    margin-right: 5px;

    &.add {
      background-color: #59e500 !important;
    }

    &.edit {
      display: none;
    }

    &.delete {
      background-color: #ff5703 !important;
    }
  }

  .changesTitle {
    display: flex;

    > span {
      font-style: italic;
    }

    small {
      color: #8d8d8d;
      margin-left: auto;
    }
  }

  .changes + .changes {
    margin-top: 10px;
  }

  .changesItems {
    display: flex;

    .changesItem {
      font-size: small;
      white-space: pre-line;
      word-break: break-word;
      flex: 1;
      //max-width: calc(50% - 14px);
      padding: 3px 5px;
      border: 1px solid #e9e9e6;
    }

    .changesItem + .changesItem {
      border-left-width: 0;
    }

    .changesDelimiter {
      padding: 0 5px;
      flex: 0 1 28px;

      svg {
        opacity: 0.3;
        margin-top: 6px;
      }
    }
  }

  .changesItems + .changesItems {
    margin-top: 5px;
  }
}
