.personCard {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;

  @media (min-width: 82rem) {
    max-width: 80%;
  }

  > div {
    flex: 1 0 auto;
  }

  @media (max-width: 66rem) {
    flex-wrap: wrap;
    margin-left: calc(70px + 1rem);

    .userData {
      margin-left: calc(-70px - 1rem);
    }
  }

  @media (max-width: 42rem) {
    gap: 0.5rem;
    flex-direction: column;
    max-width: initial;

    .userData {
      margin-bottom: -1rem;
    }
  }

  label,
  a {
    font-size: 12px;
  }

  .property {
    label:first-child {
      color: #757575;
      margin-right: 10px;
    }
  }
}

.userData {
  display: flex;
  gap: 1rem;

  .nameRow {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .nickName:global(.cds--tag) {
    margin-inline: 0 !important;
    font-size: 14px;
    line-height: 20px;
  }

  .position {
    font-size: 14px;
  }
}

.depName {
  max-width: 15rem;

  @media (max-width: 42rem) {
    max-width: unset;
  }
}
