.mentions--multiLine .mentions__control {
  font-family: BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
  line-height: 1.28572rem;
  letter-spacing: 0.16px;
  font-weight: 400;
  background-color: #f4f4f4;
}
.mentions--multiLine .mentions__highlighter {
  outline: 0;
  padding: 11px 16px;
  font-size: 0.875rem;
  line-height: 1.28572rem;
  letter-spacing: 0.16px;
}
.mentions--multiLine .mentions__input {
  padding: 11px 16px;
  outline: 0;
  line-height: 1.28572rem;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  color: #161616;
  letter-spacing: 0.16px;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  max-height: 20rem;
  overflow-y: scroll;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: white;
  border: 1px solid #8a3ffc;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: blue;
  text-shadow:
    1px 1px 1px white,
    1px -1px 1px white,
    -1px 1px 1px white,
    -1px -1px 1px white;
  pointer-events: none;
  font-size: 0.875rem;
  line-height: 1.28572rem;
  letter-spacing: 0.16px;
  font-weight: 600;
}

.mentions {
  &--disabled {
    .mentions__control {
      background-color: transparent;
    }

    .mentions__input {
      border-bottom: 2px solid #c6c6c68f;
    }
  }

  &__error {
    color: #da1e28;
    font-size: 0.75rem;
  }
}

.container {
  position: relative;
}
