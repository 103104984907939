.calendar {
  input {
    inline-size: 12rem !important;
  }
}

.authorSelect {
  .cds--select {
    display: inline-block;
  }
}

.employes {
  input {
    &::placeholder {
      color: #161616 !important;
    }
  }
}

.taskStatusesFilter {
  min-width: 250px;
  margin-right: auto;
}

.table {
  > div {
    width: 100%;
  }
}

.stats {
  width: 100%;
  margin-top: 20px;

  h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  }

  p {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    display: inline-block;
    text-align: center;
  }
}

.orange {
  color: #ffcd1c;
}
