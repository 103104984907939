.tabs {
  @media (min-width: 66rem) {
    position: sticky !important;
    top: 0 !important;
    z-index: 10;
  }

  @media (max-width: 66rem) {
    margin-top: 30px;
  }
}
