.formLink {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  align-items: center;
  word-break: break-word;

  &EditIcon {
    width: 32px;
    height: 32px;
    margin-left: 10px;
    cursor: pointer;
  }
}
