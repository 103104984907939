.content {
  display: flex;
  gap: 2rem;
}

.container {
  position: relative;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
}

.count {
  color: white;
  background-color: #8a3ffc;
  border-radius: 101px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 0;
  position: absolute;
  top: 0px;
  left: 0.75rem;
  padding: 3px;
  min-width: 0.7rem;
  min-height: 0.7rem;
}

.img {
  background-image: url('../../../assets/images/logo-1C.png');
  width: 2rem;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.aiday {
  background-image: url('../../../assets/images/logo-aiday.png');
  width: 2rem;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: contain;
  background-position: center;
}

.link {
  color: #161616;
  display: flex;
}
