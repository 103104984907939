.wrapper {
}
.gpaTitle {
  justify-content: space-between;
  > div {
    flex: 1 1;
  }
  .period {
    font-weight: bold;
    padding-left: 10px;
  }
  .performanceReview {
    padding-left: 8px;
    font-size: 14px;
  }
}
