.modalHeader {
  padding: 0 30px 10px;

  &:first-child {
    padding-top: 30px;
  }

  @media (max-width: 66rem) {
    padding: 0 15px 10px;

    &:first-child {
      padding-top: 20px;
    }
  }
}
