.table {
  > div {
    width: 100%;
  }
}

.colDescription {
  min-width: 30rem;
  white-space: pre-line;
  //display: -webkit-box;
  //-webkit-line-clamp: 5;
  //-webkit-box-orient: vertical;
  //overflow: hidden;
}

.option {
  div {
    white-space: normal;
  }
}

.colAttachment {
  min-width: 15rem;
  display: inline-flex;
  flex-wrap: wrap;
  padding: 3px 0;
  gap: 5px;
}
