.label:global(.cds--label) {
  display: block;
  white-space: nowrap;
}
.labelBold:global(.cds--label) {
  font-weight: bold;
  :global(.cds--tag) {
    font-weight: bold;
  }
}

.labelRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.labelColumn {
  flex: 0 0;
}
