.modalBody {
  min-height: initial;
  padding-bottom: 30px;

  @media (max-width: 66rem) {
    padding-bottom: 15px;
  }
}

.loaderWrapper {
  min-height: 200px;

  @media (max-width: 66rem) {
    margin-top: 10px;
  }
}
