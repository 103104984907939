.infoBox {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e9e9e6;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;

  > div:last-child {
    flex-grow: 1;
  }

  @media (min-width: 66rem) {
    position: sticky !important;
    top: 0 !important;
    z-index: 10;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}
