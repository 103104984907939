.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
}

.toolbar {
  height: 100%;
  gap: 5px;
}

.toolbarItem {
  flex: 0 0;
}
