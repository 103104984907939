.wrapper {
  position: fixed;
  z-index: 1000;
  right: 30px;
  bottom: -100%;
  transition:
    bottom 0.3s,
    right 0.3s;

  &Visible {
    bottom: 30px;
  }

  @media (max-width: 42rem) {
    right: 15px;

    &Visible {
      bottom: 15px;
    }
  }

  &Closed {
    right: -29px;
    bottom: -29px;

    @media (max-width: 42rem) {
      right: -25px;
      bottom: -25px;
    }
  }
}

.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  cursor: pointer;
  border-radius: 100%;
  padding: 12px;
  border: 1px solid #8a3ffc;
  background-color: #8a3ffc;

  > svg {
    fill: #ffffff;
    width: 32px;
    height: 32px;
  }

  @media (max-width: 42rem) {
    width: 50px;
    height: 50px;

    > svg {
      width: 24px;
      height: 24px;
    }
  }

  .wrapperClosed & {
    > svg {
      opacity: 0;
    }
  }
}

.close {
  position: absolute;
  right: -14px;
  bottom: -14px;
  padding: 2px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all 0.3s;

  > svg {
    width: 100%;
    height: 100%;
  }

  .wrapperMobile &,
  .wrapper:hover & {
    opacity: 0.5;
  }

  .wrapperClosed & {
    top: 5px;
    left: 5px;
    right: initial;
    bottom: initial;
    transform: rotate(135deg);
    opacity: 1 !important;
    color: #ffffff;

    @media (max-width: 42rem) {
      top: 2px;
      left: 2px;
    }
  }
}
