.main {
  justify-content: flex-start;
  flex-direction: row;
  flex: 1 0;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.tag {
  align-self: center;
}
