.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 11px;
  margin-bottom: 20px;
}

.header div {
  align-self: center;
}
