.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px auto;
  max-width: 970px;
  min-height: 100%;
}

:global {
  body {
    overflow-x: hidden;
  }
}
