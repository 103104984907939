.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 10px;

  &Item {
    flex: 1 0 auto;
  }

  .hierarchyCheckbox {
    flex: 0;
    white-space: nowrap;
    align-self: center;

    @media (max-width: 66rem) {
      flex: 100%;
    }
  }

  .filterableSelect {
    inline-size: auto;
    min-width: calc(50% - 5px);
    max-width: calc(50% - 5px);

    @media (max-width: 42rem) {
      min-width: 100%;
      max-width: 100%;
    }

    input {
      &::placeholder {
        color: #161616 !important;
      }
    }
  }

  .clearButton {
    flex: 0;
  }
}

.charts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (max-width: 66rem) {
    grid-template-columns: 100%;
    gap: 10px;
  }

  &Item {
    &Chart {
      height: 25vh;
    }
  }
}

.table {
  > div {
    width: 100%;
  }
}
